import Layout from "@/components/layout/Layout";
import React from "react";
import { useSession } from "next-auth/react"
import Sidebar from "@/components/layout/Sidebar";
import Topbar from "@/components/layout/Topbar";
import Link from "next/link";

const Error404 = () => {

  return (
    <div id="main-container" className="error-page">
        <div className="error-screen">
          <div className="error-wrapper">
            <div className="data-content">404 Not Found</div>
            <div className="action-wrapper">
              <Link
                href={"/dashboard"}
                className="cus-btn button-large"
              >
                Go to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>


  )
  
}

function Custom404() {

  const { data: session, status } = useSession()

  if (status === "authenticated") {
    return (
      <Layout className="main-error-page">
      {" "}
      {/** This is use to show the sidebar component */}
      <Sidebar />
      <div className="dashboard-container" id="main-container">
      <Topbar
          links={[]}
          heading=""
        />
        <Error404/>
      </div>
    </Layout>

    )
  }

  
  return (

    <Layout className="main-error-page">
      <Error404/>
    </Layout>

    
  );
}

export default Custom404;
